import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90fe99f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = {
  class: "page-header__title",
  title: "Точка учёта"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_nav = _resolveComponent("header-nav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "page-header__logo",
        onClick: _ctx.logoClicked,
        title: "Точка учёта"
      }, null, 8, _hoisted_3),
      _createTextVNode("ООО Точка Учёта ")
    ]),
    _createVNode(_component_header_nav, { onNavItemClicked: _ctx.navItemClicked }, null, 8, ["onNavItemClicked"])
  ]))
}