
import { defineComponent } from "vue";

export default defineComponent({
    name: "HeaderNav",
    methods: {
        itemClicked(item: unknown) {
            this.$emit('navItemClicked', item);
        },
        getNavItems() {
            let navItems = [
                {
                    title: "О компании",
                    id: "about"
                },
                {
                    title: "Технологии",
                    id: "techStack"
                },
                {
                    title: "Документы",
                    id: "docs"
                },
                {
                    title: "Контакты",
                    id: "contacts"
                }
            ];

            return navItems;
        }
    }
});
