import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a23f7e1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_AboutCompany = _resolveComponent("AboutCompany")!
  const _component_tech_stack_block = _resolveComponent("tech-stack-block")!
  const _component_docs_block = _resolveComponent("docs-block")!
  const _component_footer_block = _resolveComponent("footer-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["content", { content_hidden: _ctx.isHidden }]),
      ref: "content"
    }, [
      _createVNode(_component_PageHeader, { onNavItemClicked: _ctx.navItemClicked }, null, 8, ["onNavItemClicked"]),
      _createVNode(_component_AboutCompany, { ref: "about" }, null, 512),
      _createVNode(_component_tech_stack_block, { ref: "techStack" }, null, 512),
      _createVNode(_component_docs_block, { ref: "docs" }, null, 512),
      _createVNode(_component_footer_block, { ref: "contacts" }, null, 512)
    ], 2)
  ]))
}