
import { defineComponent } from "vue";

export default defineComponent({
    name: "SkillCard",
    props: {
        icon: { type: String, required: true },
        title: { type: String, required: true },
    },

    methods: {
        getImage(): string {
            let val = `url(${this.icon})`;
            return val;
        },

        getIconClass(): string {
            if (this.icon === 'net-core') {
                return 'skill-card__icon_net-core';
            }

            if (this.icon === 'react') {
                return 'skill-card__icon_react';
            }

            if (this.icon === 'avalonia') {
                return 'skill-card__icon_avalonia';
            }

            if (this.icon === 'api') {
                return 'skill-card__icon_api';
            }

            if (this.icon === 'pg') {
                return 'skill-card__icon_pg';
            }

            if (this.icon === 'redis') {
                return 'skill-card__icon_redis';
            }


            if (this.icon === 'stimul') {
                return 'skill-card__icon_stimul';
            }

            return '';
        }

    }
});
