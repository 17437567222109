
import { defineComponent } from "vue";
import HeaderNav from "./HeaderNav.vue";

export default defineComponent({
  components: { HeaderNav },
  name: "PageHeader",
  methods: {
    navItemClicked(item: any) {
      this.$emit('navItemClicked', item);
    },

    logoClicked() {
      location.reload();
    }

  }
});
