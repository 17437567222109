
import { defineComponent } from "vue";

export default defineComponent({
    name: "AboutCompanyCard",
    props: {
        number: { type: Number, required: true },
    },

    methods: {

    }
});
