import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dee12f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNavItems(), (navItem) => {
      return (_openBlock(), _createElementBlock("span", {
        key: navItem.id,
        title: navItem.title,
        class: "navigation__item",
        onClick: ($event: any) => (_ctx.itemClicked(navItem))
      }, _toDisplayString(navItem.title), 9, _hoisted_2))
    }), 128))
  ]))
}